<template>
  <div>
    <a-card title="追溯产品管理">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input v-model="searchForm.product_kw" placeholder="id或名称" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="vertical-align-bottom" @click="onUpload()">下载</a-button>
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :row-selection="{ selectedRowKeys: selectedKeys, onChange: onSelectChange }"
          :columns="columns"
          :pagination="pagination"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          @change="tableChange"
        >
          <div slot="shelf_life_days" slot-scope="value">
            <span>{{ value.goods && value.goods.shelf_life_days }}{{ value.goods && value.goods.period_unit }}</span>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="vertical-align-bottom" size="small" @click="onUpload(item)">下载</a-button>
              <a-button icon="eye" size="small" @click="openFormModal1(item)">查看</a-button>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal
            v-if="visible"
            v-model="visible"
            :oform="targetItem"
            :clientsClassificationOptions="clientsClassificationItems"
            @create="create"
            @update="update"
    />
    <form-modal1
            v-model="visible1"
            :form="targetItem1"
            :clientsClassificationOptions="clientsClassificationItems1"
    />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
  import { fromProductList, fromProductDel, generateQR } from "@/api/from";
  import { downloadByOnlineUrl,downloadByUrl} from '@/utils/upload.js'
  export default {
    name: "Warehouse",
    components: {
      FormModal: () => import("./FormModal.vue"),
      FormModal1: () => import("./FormModal1.vue")
    },
    data() {
      return {
        pagination: { current: 1, total: 0, pageSize: 20, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '500'] },
          selectedKeys: [],
        columns: [
          {
            title: "id",
            dataIndex: "product_id"
          },
          {
            title: "产品名称",
            dataIndex: "product_name"
          },
          /* {
            title: "状态",
            scopedSlots: { customRender: "status" },
            dataIndex: "status"
          }, */
          {
            title: "品牌",
            dataIndex: "goods.product_brand"
          },
          {
            title: "销售区域",
            dataIndex: "sales_area"
          },
          {
            title: "产品类型",
            dataIndex: "goods.category.name"
          },
          {
            title: "联系方式",
            dataIndex: "remark"
          },
          {
            title: "产品规格",
            dataIndex: "goods.spec"
          },
          {
            title: "执行标准",
            dataIndex: "goods.standards"
          },
          {
            title: "净含量",
            dataIndex: "net_content"
          },
          {
            title: "保质期",
            scopedSlots: { customRender: "shelf_life_days" }
          },
          {
            title: "生产企业",
            dataIndex: "commpany"
          },
          {
            title: "生产企业地址",
            dataIndex: "adreess"
          },
          {
            title: "操作",
            dataIndex: "action",
            scopedSlots: { customRender: "action" },
            width: "156px",
          },
        ],
        searchForm: { product_kw: "", page: 1, page_size: 20 },
        loading: false,
        items: [],
        clientsClassificationItems: [],
        clientsClassificationItems1: [],
        visible: false,
        visible1: false,
        targetItem: {},
        targetItem1: {},
        form: {},
        importLoading: false,
      };
    },
    methods: {
      onSelectChange(selectedRowKeys) {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.selectedKeys = selectedRowKeys;
      },
      async onUpload(record) {
        let url = '';
        /**单个下载 */
        if (record) {
          const data = await generateQR({ id: record.id });
           const url = data.data
          const name = url.split('/').pop();
          downloadByOnlineUrl(url, name || '二维码.png');
          return;
        }
        // console.log(this.selectedKeys);
        if (this.selectedKeys.length > 0) {
          if (this.selectedKeys.length === 1) {
            const data = await generateQR({ id: this.selectedKeys[0] });
           const url = data.data;
            const name = url.split('/').pop();
            downloadByOnlineUrl(
                    url,
                    name || '二维码.' + (this.selectedKeys.length > 1 ? 'zip' : 'png'),
            );
            return;
          } else {
            /**多个 */
            const data = await generateQR({ product_id: this.selectedKeys });
            const url = data.data;
            const name = url.split('/').pop();
            downloadByUrl(url, name);
            return;
          }
        } else {
          this.$message.warn('请勾选！');
        }
      },
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        fromProductList(this.searchForm)
                .then((data) => {
                  this.pagination.total = data.count;
                  this.items = data.data;
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      create() {
        this.list();
      },
      update() {
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      openFormModal(item) {
        this.targetItem = { ...item }
        if(item.product_name){
          this.targetItem.name = item.product_name + '-' + item.product_id
        }
        this.visible = true;
      },
      openFormModal1(item) {
        this.targetItem1 = { ...item }
        this.targetItem1.name = item.product_name + '-' + item.product_id
        this.visible1 = true;
      },
      destroy(id) {
        fromProductDel({ id: id })
                .then((data) => {
                  this.$message.success("删除成功");
                  this.list();
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      tableChange(pagination) {
        this.searchForm.page = pagination.current;
        this.searchForm.page_size = pagination.pageSize
        this.pagination.current = pagination.current;
        this.list();
      }
    },
    mounted() {
      this.initialize();
    },
  };
</script>
